
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a href="http://www.bradruud.com" target="_blank">
                  Brad Ruud {new Date().getFullYear()}
                </a>
              </li>
            </ul>
         </nav>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
