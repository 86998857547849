
import React from "react";

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

// core components

function SectionExamples() {
  return (
    <>
      <div className="section section-dark">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="6">
              <h2 className="title">Welcome!</h2>
              <h5 className="description">
                Feel free to browse my Project Gallery or Profile Page by clicking the links below. Thanks for visiting!
              </h5>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row className="example-page">
            <Col className="text-center" md="6">
              <a href="/project-page" >
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/examples/project1.png")}
                  style={{ width: "100%" }}
                />
              </a>
              <Button
                className="btn-outline-neutral btn-round"
                color="default"
                href="/project-page"
              >
                Project Gallery
              </Button>
            </Col>
            <Col className="text-center" md="6">
              <a href="/profile-page" >
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/examples/profile1.png")}
                  style={{ width: "100%" }}
                />
              </a>
              <Button
                className="btn-outline-neutral btn-round"
                color="default"
                href="/profile-page"
              >
                Profile Page
              </Button>
            </Col>
          </Row>
          <br />
          <br />
        </Container>
      </div>
    </>
  );
}

export default SectionExamples;
