/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";




// reactstrap components
import {
  Button,
  // Card,
  // CardBody,
  // CardFooter,
  // CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import ProfileNavbar from "components/Navbars/ProfileNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import TweeterCarousel from "views/Carousels/TweeterCarousel"
import SchedulerCarousel from "views/Carousels/SchedulerCarousel"
import BavslistCarousel from "views/Carousels/BavslistCarousel"
import TradefinderCarousel from "views/Carousels/TradefinderCarousel"

// styling for embedded videos
import "assets/css/project_examples.css"

function LandingPage() {
 
  return (
    <>
      <ProfileNavbar />
      <ProfilePageHeader />
      <div className="main section text-center">
      <Container>
        <h1 className="title">Project Gallery</h1>
              <TweeterCarousel />
            <SchedulerCarousel />
            <BavslistCarousel />
            <TradefinderCarousel />
        </Container>
      </div>
        <div className="section section-dark text-center">
          <Container >
            <Row >
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Thanks for checking out my work!</h2>
                <h5 className="description">
                For more about me, please visit my profile page.
                </h5>
                <br />
                <Button
                  className="btn-round"
                  color="light"
                  href="/profile-page"
                >
                  Profile Page
                </Button>
              </Col>
            </Row>
            <br />
              </Container>
            </div>
     
      <DemoFooter />
    </>
  );
}

export default LandingPage;
