/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  // Button,
  Container,
  Row,
  Col,
  // Form,
  // Input,
  // InputGroupAddon,
  // InputGroupText,
  // InputGroup,
} from "reactstrap";

// core components
import ProfileNavbar from "components/Navbars/ProfileNavbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";

function ProfilePage() {

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <ProfileNavbar />
      <ProfilePageHeader />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <img
                alt="..."
                className="img-circle img-no-padding img-responsive"
                src={require("assets/img/faces/Brad-icon.jpeg")}
              />
            </div>
            <div className="name">
              <h2 className="title">
                Brad Ruud
              </h2>
              <br />
            </div>
          </div>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h4>
                I'm a full-stack web developer with a passion for technology, and a drive to learn and grow in web and software development.
              </h4>
              <br />
              <h4>
                Using the knowledge and skills I have gained through my education at Lighthouse Labs, combined with my previous experience, I am confident that I will be an asset to any dev team!
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-dark text-center">
        <Container >
          <Row >
      <Col className="ml-auto mr-auto text-center" md="6">
        <h3>
          You can contact me via<a href="mailto:bradruud@gmail.com"> Email</a> or through any of the social media links above!
                </h3>
            </Col>
          </Row>
          <br />
        </Container>
      </div>
          {/* <div className="section landing-section">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8"> 
                 <Form className="contact-form">
                    <Row>
                      <Col md="6">
                        <label>Name</label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-single-02" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Name" type="text" />
                        </InputGroup>
                      </Col>
                      <Col md="6">
                        <label>Email</label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="nc-icon nc-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input placeholder="Email" type="text" />
                        </InputGroup>
                      </Col>
                    </Row>
                    <label>Message</label>
                    <Input
                      placeholder="What would you like to say?"
                      type="textarea"
                      rows="4"
                    />
                    <Row>
                      <Col className="ml-auto mr-auto" md="4">
                        <Button className="btn-fill" color="light" size="lg">
                          Send Message
                      </Button>
                      </Col>
                    </Row>
                  </Form> 
                  </Col>
              </Row>
            </Container>
          </div> */}
      
          
      <DemoFooter />
    </>
  );
};

export default ProfilePage;
