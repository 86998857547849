
import React from "react";

// reactstrap components
import {
  // Card,
  Container,
  Row,
  Col,
  // Carousel,
  // CarouselItem,
  // CarouselIndicators,
  // CarouselCaption
} from "reactstrap";

// core components

// const items = [
//   {
//     src: require("assets/img/Screenshots/tweeter_desktop.png"),
//     altText: "Tweeter Desktop View",
//     caption: "Tweeter Desktop View"
//   },
//   {
//     src: require("assets/img/Screenshots/tweeter_tablet.png"),
//     altText: "Tweeter Tablet View",
//     caption: "Tweeter Tablet View"
//   },
//   {
//     src: require("assets/img/Screenshots/tweeter.png"),
//     altText: "New tweet",
//     caption: "New tweet"
//   }
// ];

function TweeterCarousel() {
//   const [activeIndex, setActiveIndex] = React.useState(0);
//   const [animating, setAnimating] = React.useState(false);
//   const onExiting = () => {
//     setAnimating(true);
//   };
//   const onExited = () => {
//     setAnimating(false);
//   };
//   const next = () => {
//     if (animating) return;
//     const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
//     setActiveIndex(nextIndex);
//   };
//   const previous = () => {
//     if (animating) return;
//     const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
//     setActiveIndex(nextIndex);
//   };
//   const goToIndex = newIndex => {
//     if (animating) return;
//     setActiveIndex(newIndex);
//   };
  return (
    <>
      <div className="section pt-o" id="carousel">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <div class="youtube-video-container">
                <iframe title="tweeter" width="704" height="396" src="https://www.youtube.com/embed/0Oxue5dF9J4?rel=0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true"></iframe>
                </div>
              {/* <Card className="page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img src={item.src} alt={item.altText} />
                        <CarouselCaption
                          captionText={item.caption}
                          captionHeader=""
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#tweeter"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#tweeter"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Card> */}
            </Col>
          </Row>
        
        <Col className="ml-auto mr-auto text-center" md="8">
          <h6>
              Tweeter is a simple, single-page Twitter clone.

              You can view on either a desktop or mobile device. This allows you to Tweet and see your timeline update in real time!
                </h6>
          </Col>
        </Container>
      </div>
    </>
  );
}

export default TweeterCarousel;
